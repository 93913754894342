.front {
  .panel-psychiatry-homepage {
    margin-top: 20px;

    .home-chair-message {
      @include clearfix();
      border-top: 5px solid $wcm-border-gray;
      margin-bottom: 20px;
      .fieldable-panels-pane {
        div {
          position: relative;
          &:first-child {
            @include breakpoint($sm) {
              float: left;
              width: 14%;
            }
            padding-right: 20px;
            margin-top: 20px;

            @include breakpoint($xs) {
              img {
                width: auto !important;
              }
            }
          }
          &:last-child {
            @include breakpoint($sm) {
              float: left;
              width: 86%;
            }
          }
        }
      }
      h4 {
        font-size: 13px;
      }
      blockquote {
        font-size: 20px;
        line-height: inherit;
        border-left: none;
        position: relative;
        padding-left: 0;
        padding-right: 0;
        margin-bottom: 10px;

        p {
          line-height: inherit;

          span {
            display: inline-block;
            background: url(/profiles/wcmc/themes/wcmc_bootstrap/images/cta_icons/SVG/testimonials.svg) no-repeat;
            background-size: 30px 30px;
            width: 30px;
            height: 30px;
            margin-right: 10px;
          }
        }

      }
    }

    .home-header {

      .col-sm-4 {
        a {
          border-bottom: none;
          &:hover {
            border-bottom: 1px solid;
          }
        }
      }
    }

    .top-columns {

      .row {
        margin-left: 0;
        margin-right: 0;
      }
      .col-sm-6 {
        padding-left: 0;
        padding-right: 0;
        @include breakpoint($xs) {
          width: 100%;
        }
      }
      #top-2col-left {
        background: $wcm-bg-gray;
        .pane-bundle-text {
          padding: 20px;
          @include breakpoint($sm) {
            @include center();
            width: 80%;
          }
          a {
            border-bottom: none;
            &:hover {
              border-bottom: 1px solid;
            }
            &:after {
              content: '\e80d';
              @include fontello();
              font-size: 80%;
              padding-left: 10px;
            }
          }
        }
      }
      #top-2col-right {
        img {
          margin: 0;
          width: 100%;
        }
      }
    }

    .pane-clinician-search-sidebar {
      width: 100%;
      max-width: none;
      border-bottom: none;
      .clinician-search-title {
        border-bottom: 1px solid $wcm-border-gray;
        padding-bottom: 10px;
      }

      #clinician-search-form {
        width: 100%;
        #edit-search-type {
          width: 100%;
          text-align: center;
          padding-left: 0 !important;
          .form-item-search-type {
            margin: 0.5em 5px;
            padding-right: 20px;
            display: inline-block;

            input[type="radio"]:checked + label {
              font-weight: bold;
            }

            label {
              font-size: 15px;
            }
          }
        }

        #edit-keyword {
          max-width: none;
        }
      }
    }
  }

  .home-callouts {

    .row {
      margin: 0;
    }
    .col-md-6 {
      padding-left:0;
      padding-right: 0;

      .pane-bundle-image {
        &:hover {
          img {
            transform: scale(1.1);
          }

          .fieldable-panels-pane div {
            &:last-child {
              a span:after {
                width: 80%;
                left: 10%;
              }
            }
          }
        }
      }

      .fieldable-panels-pane {

        .field-basic-image-caption{
          .field-content-items{
            @include clearfix();
          }
        }

        > div {
          width: 50%;
          height: 285px;
          float: left;
          position: relative;
          overflow: hidden;
          img {
            transition: all 0.3s ease 0s;
            position: absolute;
            height: 100%;
            object-fit: cover;
          }

          &:last-child {
            background: $wcm-red;
            a {
              border-bottom: none;
              color: $wcm-white;
              position: absolute;
              width: 100%;
              height: 100%;
              font-weight: 700;

              span {
                @include center();
                width: 100%;
                text-align: center;
                padding: 0 10px;

                &:after {
                  content: '';
                  transition: all 0.3s ease 0s;
                  border-bottom: 1px solid $wcm-white;
                  position: absolute;
                  width: 0%;
                  top: 100%;
                  left: 50%;
                }
              }

              &:before {
                content: '';
                border: 1px solid rgba(255, 255, 255, 0.2);
                position: absolute;
                left: 5%;
                top: 5%;
                width: 90%;
                height: 90%;
              }

            }
          }
        }
      }

      &:nth-child(1) {
        .fieldable-panels-pane div:last-child {
          background: $wcm-dark-orange;
        }
      }
      &:nth-child(3) {
        .fieldable-panels-pane div {
          &:first-child {
            left: 50%;
          }
          &:last-child {
            left: -50%;
            background: $wcm-red;
          }
        }
      }
      &:nth-child(4) {
        .fieldable-panels-pane div {
          &:first-child {
            left: 50%;
          }
          &:last-child {
            left: -50%;
            background: $wcm-bright-orange;
          }
        }
      }
    }
  }

  .pane-news-panel-pane-3 {
    @include clearfix();
    margin-top: 20px;
    .view-header {
      text-transform: uppercase;
      font-weight: 700;
      a {
        border-bottom: none;
        &:hover {
          border-bottom: 1px solid;
        }
        &:after {
          content: '\e80d';
          @include fontello();
          font-size: 80%;
          padding-left: 10px;
        }
      }
    }

    .view-content {
      @include clearfix();

      margin: 0 -20px;

      .view-teaser {
        margin-bottom: 30px;

        .views-field-field-featured-image {
          margin-bottom: 10px;
        }

        .views-field-created {
          color: $wcm-dark-gray;
          font-family: $wcm-bold;
          font-size: 13px;
          text-transform: uppercase;
          letter-spacing: 2px;
        }

        h4 {
          margin-top: 10px;
        }
      }
    }
  }
}