.view-events-dewitt-past{
    .view-filters{
        .views-exposed-widget{
            @include breakpoint($md){
                width: 49%;

                .form-select,
                .form-item{
                    width: 100%;
                    max-width: 100%;

                    .date-padding{
                        width: 100%;
                    }
                }
            }
        }
    }
    .view-content{
        .views-row{
            margin-top: 3rem;
            border-bottom: 1px solid $wcm-border-gray;
            padding-bottom: 1rem;

            @include make-row();

            .panel-2col{
                .panel-col-first{
                    @include make-xs-column(12);
                    @include make-md-column(3);
                    @include make-md-column-push(9);

                    img{
                        max-width: 100%;
                        float: left;
                    }
    
                    @include breakpoint($md){
                        padding-top: 4rem;
                        img{
                            width: 100%;
                        }
                    }
                }
                .panel-col-last{
                    @include make-xs-column(12);
                    @include make-md-column(9);
                    @include make-md-column-pull(3);

                }
            }
        }
        .views-field-field-event-date{
            text-transform: uppercase;
            margin: .75rem 0;
            color: $wcm-dark-gray;
        }
        .views-field-field-event-date-1,
        .views-field-field-event-location{
            color: $wcm-dark-gray;
        }
        .views-field-field-event-image{
            margin-left: 1rem;
        }
    }
}

.view-events-dewitt-landing{
    margin-bottom: 3rem;
    .view-header{
        text-align: center;
        margin-top: 4rem;
        margin-bottom: 1rem;
    }
    .view-content{
        overflow: auto;
        .views-row{
            @include make-xs-column(12);
            @include make-sm-column(6);
            @include make-md-column(3);
            .views-field{
                margin-top: 1rem;
                margin-bottom: 1rem;
            }
        }
    }
}

.view-video-archive{
    margin-top: 3rem;
    .view-content{
        .views-row{
            border-bottom: 1px solid $wcm-border-gray;
            padding-bottom: 2rem;
            margin-bottom: 3rem;

            .views-field-created{
                text-transform: uppercase;
                color: $wcm-dark-gray;
            }

            .views-field-field-video-url{
                margin-bottom: .5rem;
            }
        }
    }
}