@mixin psychiatry-multi-column($count: 3) {
  -moz-column-count: $count;
  -webkit-column-count: $count;
  -ms-column-count: $count;
  -o-column-count: $count;
  column-count: $count;
}

@mixin psychiatry-multi-gap($width: 2rem) {
  -webkit-column-gap: $width;
  -moz-column-gap: $width;
  -ms-column-gap: $width;
  -o-column-gap: $width;
  column-gap: $width;
}
