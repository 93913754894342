#primary-nav {
    ul {
      li.level-1 {
        padding: 8px 3px;
        width: 20%;
  
        @include breakpoint($lg) {
          width: 18%;
        }
  
        &:nth-child(2) {
          @include breakpoint($lg) {
            width: 28%;
          }
        }
  
        &.active {
          &:after {
            @include breakpoint($md) {
              border-bottom: none;
            }
            @include breakpoint($lg) {
              border-bottom: 10px solid $wcm-dark-orange;
            }
          }
        }
  
      }
  
    }
  }
  
  
  #bc-nav {
    position: relative;
    z-index: 997;
  }
  
  .form-select {
    // max-width: none;
    min-width: 240px;
    height: 50px;
    border: 1px solid $wcm-border-gray;
    box-shadow: none;
    appearance: none;
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'%3E%3Cpath d='M207 381.5L12.7 187.1c-9.4-9.4-9.4-24.6 0-33.9l22.7-22.7c9.4-9.4 24.5-9.4 33.9 0l154.7 154 154.7-154c9.4-9.3 24.5-9.3 33.9 0l22.7 22.7c9.4 9.4 9.4 24.6 0 33.9L241 381.5c-9.4 9.3-24.6 9.3-34 0z' fill='%23cf4520'/%3E%3C/svg%3E");
      background-repeat: no-repeat;
      background-position: right 15px top 50%;
    background-size: 20px 20px;
    padding-right: 50px;
  }
  
  .panel-psychiatry-home {
    #psychiatry-home-header {
      .container-liquid {
        padding: 0;
        .row {
          margin: 0 auto;
        }
      }
    }
    #psychiatry-home-content-header {
      .pane-fieldable-panels-pane {
        .pane-title {
          margin-bottom: 1rem;
          text-align: center;
        }
        .fieldable-panels-pane {
          font-size: 1.6rem;
          color: #555;
        }
      }
    }
    #top-2col-left, #bottom-2col-left, #footer-2col-left {
      padding: 0;
      color: #555;
      @include breakpoint($md) {
        padding-left: 0;
        padding-right: 0.8rem;
      }
      .pane-title {
        .external-link {
          position: relative;
          &:after {
            font-size: 80%;
            padding-left: 0.8rem;
            line-height: 1.5em;
          }
        }
      }
    }
    #top-2col-right, #bottom-2col-right, #footer-2col-right {
      padding: 0;
      color: #555;
      @include breakpoint($md) {
        padding-left: 0.8rem;
        padding-right: 0;
      }
      .pane-title {
        .external-link {
          position: relative;
          font-size: 2.6rem;
          padding-left: 4rem;
          &:after {
            content: '' !important;
          }
          &:before {
            content: '';
            position: absolute;
            background: url(/../profiles/wcmc/themes/wcmc_bootstrap/images/cta_icons/sprite/sprite.svg) no-repeat;
            background-size: 1920%;
            background-position: -432px -36px;
            width: 30px;
            height: 30px;
            opacity: 1;
            left: 0px;
            top: -5px;
          }
        }
      }
    }
    #footer-2col-left {
      .pane-fieldable-panels-pane {
        img {
          max-width: 100%;
          width: 100%;
          height: auto;
        }
      }
    }
    #footer-2col-right {
      .pane-fieldable-panels-pane {
        border: 2px solid #eee;
        padding: 2rem;
        background: #f7f7f7;
        h3 {
          margin-top: 0.4rem;
          margin-bottom: 1rem;
        }
      }
    }
    #psychiatry-home-top {
      .panel-pane {
        position: relative;
        a {
          .top-2col-image-banner {
            position: absolute;
            color: #fff;
            bottom: -2px;
            left: 0;
            right: 0;
            padding: 0.4rem 0;
            max-width: 100%;
            font-size: 2.2rem;
            background: #b31b1b;
            z-index: 1;
            text-align: center;
            opacity: 0.75;
            -webkit-transition: all 0.8s ease;
            -moz-transition: all 0.8s ease;
            -o-transition: all 0.8s ease;
            -ms-transition: all 0.8s ease;
            transition: all 0.8s ease;
          }
          img {
            width: 100%;
            -webkit-transition: all 0.8s ease;
            -moz-transition: all 0.8s ease;
            -o-transition: all 0.8s ease;
            -ms-transition: all 0.8s ease;
            transition: all 0.8s ease;
            z-index: 0;
          }
          &:hover {
            img {
              opacity: 0.8;
            }
            .top-2col-image-banner {
              background: #555;
            }
          }
        }
      }
    }
    #psychiatry-home-bottom {
      margin: 2rem 0;
      .panel-pane {
        .bottom-2col-image {
          float: left;
          margin-right: 2rem;
          img {
            height: auto;
            @include breakpoint($md) {
              min-height: 25rem;
            }
          }
        }
        .bottom-2col-text-wrapper {
          h3 {
            margin: 0;
          }
          a.btn {
            margin-top: 0.6rem;
          }
        }
        .pane-title {
          margin-bottom: 0.5rem;
          a:after {
            line-height: 2;
          }
        }
        clear: both;
      }
    }
    #bottom-2col-left,
    #bottom-2col-right {
      display: inline-block;
      h3 {
        margin: 0;
        color: #555;
      }
    }
    #bottom-2col-right {
      margin-top: 2rem;
      @include breakpoint($md) {
        margin-top: 0;
      }
    }
  }
  
  .panel-hero-3col {
    div.caption-left {
      margin: 5px 20px 0px 0;
    }
    .view-biblio-views {
      .biblio-details {
        padding: 1rem 0px;
        .views-field {
          line-height: 2.8rem;
          background-color: #F6F6F6;
          border-bottom: 1px solid #DDD;
          font-size: 1.6rem;
          padding: 0.8rem;
          .views-label {
            display: inline;
            @include breakpoint($md) {
              width: 22%;
              float: left;
              display: inline-block;
            }
          }
          .field-content {
            display: inline;
            @include breakpoint($md) {
              width: 78%;
              display: inline-block;
            }
          }
          &:before {
            clear: both;
          }
        }
      }
    }
    .pane-individual-studies-panel-pane-1 {
      .views-row {
        padding-bottom: 3.4rem;
        .views-field {
          background-color: #F6F6F6;
          border-bottom: 1px solid #DDD;
          font-size: 1.4rem;
          padding: 0.8rem;
          line-height: 1.6;
          .views-label {
            display: inline;
            font-weight: 800;
            @include breakpoint($md) {
              width: 22%;
              float: left;
              display: inline-block;
            }
          }
          .field-content {
            display: inline;
            @include breakpoint($md) {
              width: 78%;
              display: inline-block;
            }
            > p {
              margin: 0;
            }
          }
          &:before {
            clear: both;
          }
        }
        .views-field-title {
          padding: 0 0 1rem 0;
          margin: 0;
          background-color: #fff;
          .field-content {
            @include breakpoint($md) {
              width: 100%;
            }
          }
          h3 {
            margin: 0;
          }
        }
      }
    }
    .pane-profiles-panel-pane-3 {
      .view-content {
        > h2, > h3 {
          clear: both;
          margin-bottom: 1.5rem;
          font-size: 2.4rem;
        }
        > h2:not(:first-child), > h3:not(:first-child) {
          border-top: #efefef solid 1px;
          padding-top: 1.5rem;
        }
        div.views-row {
          min-height: 35.5rem;
          @include breakpoint($md) {
            float: left;
            width: 50%;
          }
          .profile-list-image {
            img {
              max-width: 12.5rem;
            }
          }
          .views-field-nothing {
            margin-top: 1rem;
            .display-name {
              font-weight: 800;
            }
          }
          .views-field-field-professional-title {
            margin-bottom: 1rem;
          }
          &:last-child {
            clear: both;
          }
        }
        &:after {
          clear: both;
        }
      }
    }
    .pane-wcmc-localist-pane {
      div.localist-event {
        display: inline-block;
        margin-bottom: 2rem;
        padding-bottom: 1.6rem;
        border-bottom: 1px solid #efefef;
        clear: both;
        > a {
          @include breakpoint($md) {
            float: left;
            width: 24%;
            margin-right: 2rem;
          }
          > img {
            margin-bottom: 2rem;
          }
          + .event-details {
            @include breakpoint($md) {
              width: 72%;
              float: left;
              margin-right: 0px;
            }
          }
        }
        .event-details {
          margin-top: -2rem;
          .event-title {
            a {
              &:after {
                content: '\e80d';
                padding-top: 5px;
                padding-left: 8px;
              }
            }
          }
        }
      }
    }
    .pane-training-panel-pane-1, .pane-training-panel-pane-2 {
      margin-top: 3rem;
    }
    .locations-wrapper {
      clear: both;
      display: inline-block;
      width: 100%;
      .locations-image {
        @include breakpoint($md) {
          float: left;
          width: 50%;
          margin-right: 2rem;
        }
      }
      .locations-address {
        @include breakpoint($md) {
          float: left;
          width: 46%;
          margin-top: -2rem;
        }
      }
    }
    .wcm-faculty-search {
      margin-top: 3rem;
    }
  }
  
  .information-column {
    text-align: center;
    .pane-research-opp-pane {
      width: 100%;
      margin: 0px auto 4rem auto;
      width: 220px;
      @include breakpoint($md) {
        width: 245px;
      }
      @include breakpoint($lg) {
        width: 255px;
      }
      .wcm-cta__research-opp-sidebar {
        text-align: center;
        border-bottom: 1px solid $wcm-border-gray;
        padding: 0 0 0.8rem 0;
        .wcm-cta-wrapper {
          .cta-icon {
            color: #b31b1b;
            fill: #e97623;
            font-size: 8rem;
            padding: 0 1rem 1rem 1rem;
            margin-top: -0.5rem;
          }
          .research-opp-title {
            position: relative;
            bottom: 3px;
            left: 4px;
            font-size: 13px;
            letter-spacing: -0.2px;
            display: inline-block;
          }
        }
      }
      .research-opp-desc-wrapper {
        margin: 1rem 1.2rem;
        font-size: 13px;
      }
    }
    .pane-psychiatry-goback-pane,
    .pane-psychiatry-cta-pane {
      margin: 0px auto 4rem auto;
      width: 220px;
      @include breakpoint($lg) {
        width: 245px;
      }
      .psychiatry-wcm-cta {
        text-align: center;
        border-bottom: 1px solid rgb(221, 221, 221);
        padding-bottom: 2rem;
        a {
          outline: 0;
        }
        .wcm-cta__archive-sidebar {
          .cta-icon {
            margin-left: -1.4rem !important;
            font-size: 3.8rem;
            color: #b31b1b;
            fill: #e97623;
          }
          > span {
            position: relative;
            bottom: 12px;
            left: 8px;
            font-size: 15px;
            color: #b31b1b;
          }
        }
        .btn--wcm:before {
          top: 1px;
        }
      }
    }
    .pane-psychiatry-goback-pane {
      margin: 0rem auto 2rem auto;;
      .psychiatry-wcm-cta {
        padding-bottom: 2rem;
        .btn--wcm {
          &:before {
            top: 1px;
            font-family: "fontello";
            content: '\e810';
            left: 30%;
          }
          &:hover:before {
            left: 10%;
          }
        }
      }
    }
    .pane-wcmc-contact-pane {
      width: 100%;
      text-align: center;
      .contact-info {
        h4 {
          margin-top: -1.6rem;
        }
      }
    }
    .wcm-side-bar-title {
      margin: -2rem 0 2rem 0;
      text-align: center;
    }
  }
  
  footer {
    .footer-contact {
      .footer-contact__site-name a {
        font-size: 15px;
        @include breakpoint($md) {
          font-size: 18px;
        }
      }
    }
    .footer-site {
      .footer-address {
        border-right: none;
        margin-top: -0.3rem;
        @include breakpoint($sm) {
          width: 28% !important;
        }
        @include breakpoint($md) {
          width: 18% !important;
        }
      }
      .footer-nav {
        margin-bottom: 2rem;
        @include breakpoint($sm) {
          width: 72% !important;
          border-left: 1px solid #DDD;
        }
        @include breakpoint($md) {
          width: 82% !important;
        }
        .block {
          @include psychiatry-multi-column;
          @include psychiatry-multi-gap;
          ul.menu {
            width: 85%;
            li:nth-child(3) {
              display: inline-block;
            }
          }
        }
      }
    }
  }
  
  
  .pane-clinician-search-sidebar {
    margin-top: 0;
    border-top: 5px solid $wcm-border-gray;
    border-bottom: 5px solid $wcm-border-gray;
    padding-top: 10px;
  
    .clinician-search-wrapper {
      background: none;
      color: inherit; 
      padding: 0;
  
      #edit-search-type {
        width: 50%;
        margin: 0 auto; 
      }
  
      #edit-keyword {
        max-width: none !important;
      }
  
      .form-item {
        margin-bottom: 10px;
  
        label {
          // font-weight: bold;
          padding-left: 0;
          color: $wcm-dark-gray; 
        }
        input[type="radio"]:checked + label {
          font-weight: bold; 
        }
      }
  
      .btn--wcm {
        padding: 10px 20px;
        margin-bottom: 10px;
      }
  
      p {
        font-size: 13px;
        text-align: center;
      }
  
      .clinician-search-title {
        text-align: center;
        text-transform: uppercase;
        font-weight: 700;
        font-size: 14px;
        color: $wcm-dark-gray;
        padding-bottom: 5px;
      }
    }
  }
  
  h3.center {
    margin-bottom: 1rem;
      text-align: center;
  }
  
  .psychiatry-footer-banner {
    background: $wcm-dark-orange url(../images/hospital.jpg) center no-repeat;
    background-size: cover;
    padding: 50px 0;
    background-blend-mode: multiply;
    position: relative;
    margin-bottom: -20px;
  
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
      &:after{
        content:'';
        background: transparentize(darken($wcm-dark-orange, 8), .2);
        position: absolute;
        top:0;
        bottom:0;
        width:100%;
      }
    }
  
    .banner-image-container {
      position: relative;
      z-index: 1;
      text-align: center;
  
      &:after {
        content: '';
        background: $wcm-white;
        position: absolute;
        width: 100%;
        height: 80%;
        left: 0;
        top: 10%;
      }
  
  
      img {
  
        &.logo-nyp {
          max-width: 190px;
          -webkit-transform: translateY(-70%);
          -ms-transform: translateY(-70%);
          transform: translateY(-70%);
        }
  
        max-width: 130px;
        margin-bottom: 0;
        margin-left: 10px;
        margin-right: 10px;
        position: relative;
        z-index: 1;
      }
    }
  }
  
  .panel-psychiatry-services {
  
    .pane-services-panel-pane-4 {
      margin-top: 10px;
  
      .element__hide {
        padding-right: 10px;
  
        .service-description {
          padding-right: 50px;
        }
      }
  
      .service-fap-callout {
        @include clearfix();
        margin-bottom: 10px;
        background: $wcm-bg-gray;
        p {
          margin-top: 0;
        }
        .col-md-4 {
          @include breakpoint($md) {
            text-align: right;
          }
        }
        .btn--wcm {
          margin-top: 20px;
          @include breakpoint($md) {
            display: inline-block;
          }
        }
      }
    }
  
    .content-header {
      .col-sm-12 {
        text-align: center;
        @include breakpoint($sm) {
          padding: 0 100px 20px;
        }
      }
    }
    .content-top {
  
      #top-col-left {
        float: none;
        @include breakpoint($sm) {
          padding: 0 100px 20px;
        }
  
        .pane-bundle-text {
          text-align: center;
        }
      }
      .pane-clinician-search-sidebar {
        border-top: none;
        border-bottom: none;
        margin-top: 10px;
        max-width: none;
        width: 100%;
  
        .clinician-search-wrapper {
          .clinician-search-title {
            border-bottom: 1px solid $wcm-border-gray;
            padding-bottom: 20px;
            font-size: 15px;
          }
  
          #clinician-search-form {
            width: 100%;
            #edit-search-type {
              width: 100%; 
              text-align: center;
              padding-left: 0 !important;
              .form-item-search-type {
                margin: 0.5em 5px;
                padding-right: 20px;
                display: inline-block;
  
                input[type="radio"]:checked + label {
                  font-weight: bold;
                }
  
                label {
                  font-size: 15px;
                }
              }
            }
            
            #edit-keyword {
              max-width: none;
            }
          }
        }
      }
  
      .services-callout {
        background: $wcm-dark-orange;
        color: $wcm-white;
  
        a {
          color: $wcm-white;
          border-bottom: none;
          &:hover {
            border-bottom: 1px solid;
          }
  
          img {
            margin-bottom: 0;
          }
  
        }
  
  
        .fieldable-panels-pane div:last-child {
          padding: 10px 20px 10px;
        }
      }
    }
    .content-middle {
      padding: 40px 20px; 
      margin-top: 20px;
      background: $wcm-bg-gray;
  
      #middle-col-left {
        border-bottom: 1px solid $wcm-border-gray;
        @include breakpoint($md) {
          padding-right: 90px;
          border-right: 1px solid $wcm-border-gray;
          border-bottom: none;
        }
        .pane-bundle-text {
          a {
            &:after {
              content: '\e80d';
              @include fontello();
              font-size: 80%;
              padding-left: 10px;
            }
          }
        }
      }
      #middle-col-right {
        @include breakpoint($md) {
          padding-left: 90px;
        }
  
        .pane-bundle-text {
          a {
            &:after {
              content: '\e80d';
              @include fontello();
              font-size: 80%;
              padding-left: 10px;
            }
          }
        }
      }
    }
  
    .content-bottom {
      padding-bottom: 10px;
  
      .pane-bundle-image {
        .pane-title {
          margin-bottom: 20px;
          font-size: 30px;
        }
        img {
          margin-bottom: 20px;
        }
        .col-sm-9 {
          padding-left: 0;
        }
        .col-sm-3 {
          border-left: 1px solid $wcm-border-gray;
  
          .btn--wcm {
            width: auto;
            &:before {
              padding-left: 10px;
            }
          }
        }
      }
    }
  }
  
  .view-post-graduate-career-plans {
    .views-exposed-form {
      .views-exposed-widget {
        // float: none;
  
        .form-item {
          width: 100%;
  
          .date-padding {
            float: none;
            width: 100%;
          }
        }
      }
    }
  }
  
  .view-resident-stories {
  
    h3 {
      border-bottom: 1px solid $wcm-border-gray;
      margin-bottom: 10px;
      padding-bottom: 10px;
    }
  
    .views-row {
      border-bottom: 1px solid $wcm-border-gray;
      padding-top: 20px;
      padding-bottom: 20px;
  
      &.views-row-last {
        border-bottom: none; 
      }
    }
    .views-field-body {
      font-size: 18px;
      font-family: $wcm-italic;
      color: $wcm-dark-gray;
      line-height: inherit;
      border-left: none;
      position: relative;
      padding-left: 60px;
  
      &:before {
        display: block;
        content: '';
        width: 40px;
        height: 40px;
        position: absolute;
        left: 0;
        top: 0px;
        background: url(/profiles/wcmc/themes/wcmc_bootstrap/images/cta_icons/SVG/testimonials.svg) center center no-repeat;
        background-size: 40px 40px;
      }
    }
  
    .views-field-title {
      text-align: right;
      color: $wcm-black;
      font-family: $font-family-sans-serif;
      font-style: normal;
      font-weight: bold;
      font-size: 15px;
      display: block;
    }
  
    p {
      margin-bottom: 0;
    }
  }
  
  .btn--wcm {
    padding: 10px 20px;
    margin-bottom: 20px;
  
    &:before {
      line-height: 2.5;
    }
  }
  
  .registration-payment {
    background: $wcm-bg-gray;
    text-align: center;
    padding: 20px 20px 30px;
    margin: 20px 0;
  
    table {
      margin: 20px auto 0; 
      width: 100%;
      max-width: 550px;
      text-align: left;
  
      tbody {
        border: none;
      }
  
      span.training-label {
        font-size: 13px;
        font-weight: bold;
      }
  
    }
  
    .paypal-logo {
      width: 250px;
    }
    .select2-container {
      width: 100% !important;
      max-width: 550px;
    }
  }
  ///////
  // Full hero layout
  .panel-psychiatry-fullhero-3col {
    position: relative;
    @include clearfix();
  
    // This overrides a style coming from Radix Layouts. Our gutter is a little bigger.
    .container .container {
      margin-left: -20px;
      margin-right: -20px;
    }
  
    .hero-image--full {
      width: 100%;
      height: 175px;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      margin: 0 0 20px;
      @include breakpoint($sm) {
        height: 300px;
      }
    }
  
    #related-content-sidebar {
      display: none;
      @include breakpoint($sm) {
        width: 220px;
        margin-left: 40px;
        float: right;
        display: block;
      }
    }
  
    .information-sidebar {
      line-height: 1.7;
    }
  
    .no-js #related-content-sidebar {
      display: block;
    }
  
    #related-content-inline {
      margin-bottom: 2em;
      @include breakpoint($sm) {
        display: none;
      }
    }
  
    .pane-node-field-related-articles {
      clear: both;
    }
  }
  
  .view-resident-profiles {
    @include clearfix();
    margin-top: 30px;
  
    .view-grouping {
      @include clearfix();
  
      .view-grouping-header {
        font-family: $wcm-bold;
        color: $wcm-red;
        font-size: 30px;
      }
  
      .view-grouping-content {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
  
        h3 {
          margin-top: 0;
          margin-bottom: 10px;
          clear: both;
          width: 100%;
        }
      }
    }
    .views-row {
      width: 50%;
      float: left;
      padding-right: 20px;
      margin-bottom: 20px;
  
      &:nth-child(odd) {
        clear: left;
      }
  
      @include breakpoint($sm) {
        width: 20%;
        
        &:nth-child(odd) {
          clear: none;
        }
        
      }
  
      .views-field-field-resident-profile-image {
        img {
          border-top: 5px solid $wcm-red;
        }
      }
      .views-field-nothing {
        font-weight: bold;
        .resident-medical-school {
          font-size: 14px;
          font-weight: normal;
          span {
            font-weight: bold;
          }
        }
      }
    }
  }
  
  .pane-fellowship-submission {
    clear: both;
    margin-bottom: 30px;
    .submission-wrapper {
      @include clearfix();
      padding: 20px 40px 40px;
      background: $wcm-bg-gray;
  
      .submission-email {
        margin-top: 20px;
        border-bottom: 1px solid $wcm-border-gray;
        @include breakpoint($sm) {
          float: left;
          width: 40%;
          border-right: 1px solid $wcm-border-gray;
          border-bottom: none;
          padding-right: 30px;
        }
  
        .submission-title {
          font-family: $wcm-bold;
          font-size: 20px;
          height: 40px;
          background: url(/profiles/wcmc/themes/wcmc_bootstrap/images/cta_icons/SVG/website.svg) left center no-repeat; 
          background-size: 40px 40px;
          padding-left: 50px;
          padding-top: 5px;
          margin-bottom: 10px;
        }
      }
  
      .submission-mail {
        margin-top: 20px;
        @include breakpoint($sm) {
          float: left;
          width: 60%;
          padding-left: 80px;
        }
  
        .submission-title {
          font-family: $wcm-bold;
          font-size: 20px;
          height: 40px;
          background: url(/profiles/wcmc/themes/wcmc_bootstrap/images/cta_icons/SVG/email.svg) left center no-repeat; 
          background-size: 40px 40px;
          padding-left: 50px;
          padding-top: 5px;
          margin-bottom: 10px;
        }
      }
    }
  }
  
  .pane-news-panel-pane-1 {
    .view-teaser {
      border-bottom: 1px solid $wcm-border-gray;
      padding-bottom: 20px;
      .post-date {
        color: $wcm-dark-gray;
        font-family: $wcm-bold;
        font-size: 13px;
        text-transform: uppercase;
        letter-spacing: 2px;
        margin-top: 5px;
      }
  
      .teaser-image {
        img {
          max-width: none;
          width: auto;
          float: none;
          @include breakpoint($sm) {
            float: right;
            max-width: 25%;
            width: 25%;
            margin-left: 20px;
            margin-right: 0;
          }
        }
      }
    }
  }
  
  /*** Clinical Trials ***/
  .view-clinical-trials {
  
      .views-row {
  
          border-bottom: 1px solid $wcm-border-gray;
      padding-bottom: 20px;
      margin-bottom: 15px;
  
          .trial-title {
            padding-bottom: 10px;
           font-size: 17px;
      }
  
      .views-field {
              padding-bottom: 2px;
              .views-label {
                  font-weight: 600;
              }
              .field-content {
                  display: inline;
              }
          }
  
          .views-field-nothing {
              padding-top: 10px;
          }
      }
  
      a.pc-btn-small {
          display: inline-block;
          background-color: $wcm-dark-orange;
          border-radius: 7px;
          height: 30px;
          line-height: 30px;
          padding: 0 14px;
          color: #fff;
          margin-right: 10px;
          font-size: 13px;
      }
  }
  
  .pane-clinical-studies {
    .dropdown-wrapper {
        label {
            display: block;
            font-size: 13px;
        }
        margin-bottom: 20px;
    }
  
    .trial-item {
        margin-bottom: 10px;
        h3 {
          padding-bottom:10px; 
        }
        .views-field {
          background-color: $wcm-bg-gray;
          border-bottom: 1px solid $wcm-border-gray;
          &:first-of-type {
            border-top: 1px solid $wcm-border-gray;
          }
          font-size: 1.4rem;
          padding: 0.8rem;
          line-height: 1.6;
          .views-label {
            display: inline;
            font-weight: 800;
            @include breakpoint($md) {
              width: 22%;
              float: left;
              display: inline-block;
            }
          }
          .field-content {
            display: inline;
            @include breakpoint($md) {
              width: 78%;
              display: inline-block;
            }
            > p {
              margin: 0;
            }
          }
          &:before {
            clear: both;
          }
        }
    }
  
    .trial-no-results {
        display: none;
    }
  }
  
  .file-icon[alt="PDF icon"] {
      background-image: url(/profiles/wcmc/themes/wcmc_bootstrap/images/cta_icons/SVG/pdf.svg);
      background-repeat: no-repeat;
      background-size: 20px 20px;
      height: 20px;
      width: 20px;
      position: relative;
      padding-left: 25px;
  }
  
  .page-node-13 {
    .ctools-collapsible-content {
      a:not([href$=".pdf"]) {
        display: inline-block;
        font-size: 13px;
        color: #fff;
        background-color: $wcm-dark-orange;
        border-color: $wcm-dark-orange;
        border-radius: 10px;
        line-height: 18px;
        padding: 5px 20px;
      }
    }
  }
  
  .pane-bundle-headshot {
    .headshot-entry:nth-child(3n+4) {
      @include breakpoint( (min:600px) ) {
        clear: left;
      }
      @include breakpoint($sm) {
        clear: none;
      }
      @include breakpoint($md) {
        clear: left; 
      }
      @include breakpoint($lg) {
        clear: none;
      }
    }
  
    .headshot-entry:nth-child(4n+5) {
      @include breakpoint($sm) {
        clear: left;
      }
      @include breakpoint($md) {
        clear: none;
      }
      @include breakpoint($lg) {
        clear: left; 
      }
    }
  }